@import './dmhub.css';

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
}

iframe {
    display: none !important;
}

.page-top-word {
  position: absolute;
  left: 32px;
  top: 24px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 720px;
}

#root {
  width: 100%;
  height: 100%;

  >.root-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    > :first-child {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 800px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    > :last-child {
      width: 540px;
      min-height: 800px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #ffffff;

      form {
        width: 346px;

        div {
          width: 346px;
          height: 48px;

          span {
            height: 48px;

            svg {
              position: absolute;
              right: 18px;
              top: 16px;
            }
          }

          input {
            width: 346px;
            height: 42px;
          }

          input[name=username] {
            height: 48px;
          }

          input[name=phone] {
            height: 48px;
          }

          input[name=smsCode] {
            height: 48px;
          }

          input[name=password] {
            margin-bottom: 48px;
          }
        }
      }

      position: relative;

      [name=copyright] {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: 24px;
        font-size: 12px;
        white-space: nowrap;
        color: gray;
      }

      [name=copyright] [alt="logo"] {
        vertical-align: top; // 为了垂直居中对齐
      }
    }
  }

  .reset-password {
    position: relative;
    margin: auto;
    width: 346px;

    p {
      margin-bottom: 0;
    }

    .reset-p {
      margin-bottom: 16px;
    }

    .verycode-to-p {
      margin-bottom: 16px;

      span {
        font-size: 14px;
        margin-left: 16px;
      }
    }

    .verycode-input-p {
      position: relative;
      margin-bottom: 16px;

      .span-getcode {
        position: absolute;
        right: 16px;
        top: 10px;
        color: #3369FF;
        font-size: 14px;
        cursor: pointer;
        height: 24px;
        padding-left: 16px;
        border-left: 1px solid #EBECF2;
        ;
      }

      .span-count {
        position: absolute;
        right: 16px;
        top: 10px;
        color: #3369FF;
        font-size: 14px;
        cursor: pointer;
        width: 87px;
        text-align: center;
        height: 24px;
        padding-left: 16px;
        border-left: 1px solid #EBECF2;
        ;
      }
    }

    .verycode-input-p input {
      width: 346px;
      height: 48px;
    }

    input[name=mobile] {
      width: 346px;
      height: 48px;
      margin-bottom: 48px;
    }

    input[name=resetpassword] {
      height: 40px;
    }

    input[name=resetpasswordsure] {
      height: 40px;
    }

    .next-btn,
    .reset-sure-btn {
      width: 346px;
      height: 48px;
    }

    .inputpassword {
      height: 48px;
      margin-bottom: 16px;
    }

    .inputpasswordsure {
      height: 48px;
      margin-bottom: 48px;
    }
  }
}

#login {
  .page-login-method {
    margin-bottom: 29px;

    .span-code-login {
      color: #6C7482;
      font-size: 14px;
      cursor: pointer;
      display: inline-block;
      margin-right: 32px;
      padding-bottom: 16px;
    }

    .hot {
      font-size: 16px;
      color: #212021;
      border-bottom: 2px solid #212021;
    }
  }

  .forget-password {
    text-align: right;
    color: #3369FF;

    span {
      cursor: pointer;
    }
  }

  .sms-code-wrap {
    position: relative;

    .span-getcode {
      position: absolute;
      right: 16px;
      top: 10px;
      color: #3369FF;
      font-size: 14px;
      cursor: pointer;
      height: 24px;
      padding-left: 16px;
      border-left: 1px solid #EBECF2;
      ;
    }

    .span-count {
      position: absolute;
      right: 16px;
      top: 10px;
      color: #3369FF;
      font-size: 14px;
      cursor: pointer;
      width: 87px;
      text-align: center;
      height: 24px;
      padding-left: 16px;
      border-left: 1px solid #EBECF2;
      ;
    }
  }

  position: relative;
  margin: auto;
}

#login h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: #1A263B;
  margin-bottom: 28px;
  position: relative;
  text-overflow: ellipsis;
  width: 340px;
  white-space: nowrap;
  overflow-x: hidden;

  span {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
  }

  img {
    position: absolute;
    left: 86px;
    top: 5px;
  }
}

#login .logo {
  width: 48px;
//   height: 48px;
  // margin-top: 190px;
  margin-bottom: 24px;
}

#login .logo.portal {
  width: 100%;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 25px;
  img {
    height: 32px;
    width: unset !important;
  }
}

#login .login-btn {
  width: 346px;
  height: 48px;
}

#login .login-btn-idp {
  margin-top: 16px;

  img {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
}